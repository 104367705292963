<template>
  <div class="liveVideo">
    <div class="searchBox">
      <!-- 搜索 -->
      <search-input
        class="searchInput"
        ref="searchInput"
        :placeholder="$t('lang.i18n_shareSearchTText')"
        :callback="searchR"
        :value="params.condition"></search-input>
      <el-button
        type="success"
        class="refreshBtn"
        @click="refreshRList('refresh')">{{$t('lang.i18n_refresh')}}</el-button>
      <!-- 分页组件 -->
      <Pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :pageSizes="[8, 20, 30, 50]"
        :pageSize="pageSize"
        :total="total"
        layout="prev, pageF, next, pageL, nowSize, jumper" />
    </div>
    <div
      class="mainBox"
      v-loading="loading">
      <ul
        class="sourceBox clearfix"
        id="sourceBox"
        element-loading-background="transparent"
        :sharing-social="sharingSocial">
        <Source
          v-for="item in showRList"
          :key="item.device.peerId"
          :item="item.device"
          :shareSize="item.shareSize"
          :shareInfo="item.shareInfo" />
      </ul>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import Source from './Source'
import Pagination from '@/components/Pagination'
import SearchInput from '@/components/Search'
export default {
  name: 'LiveVideo',
  data () {
    return {
      // 分页组件
      currentPage: 1,
      pageSize: 8,
      total: 10,

      loading: false,
      videoList: [],
      showRList: [],
      sharingSocial: [],
      sharingR: [],
      sockentList: null,
      allPeerId: [],
      videoObj: null,
      params: {
        condition: '',
        receiver: true,
        pack: false,
        anywhere: false,
        gridPack: false,
        gridSdi: false,
        automatic: true,
        manual: true,
        noPosition: true,
        bookmardIds: 'all',
        unClassified: true,
        token: false,
        pageNum: 1,
        pageSize: 99999,
        withGroup: true,
        address: false
      }
    }
  },
  computed: {
    ...mapState({
      // 映射
      State: state => state,
      pageRequest: state => state.websocket.pageRequest
    })
  },
  mounted () {
    this.refreshRList('init')
    mediaObj.searchFlag = false // 处理搜索接口与websocket中间延迟问题
  },
  methods: {
    getCurrentUserR (tag) {
      if (this.pageRequest) {
        this.pageRequest.stop('taskInfo_TR')
      }
      this.videoList = []
      this.loading = true
      this.axios.get('/ccp/tvucc-user/deviceGeo/page', { params: this.params, isCancelToken: true }).then(res => {
        if (res.data.errorCode == '0x0') {
          let result = res.data.result
          let arr = []
          if (result.list.length) {
            result.list.forEach(item => {
              arr.push(item.peerId)
            })
            this.allPeerId = JSON.parse(JSON.stringify(arr))
            this.sendScoket()
          } else {
            this.loading = false
          }
        } else {
          this.loading = false
        }
      }).catch(() => {
        this.loading = false
      })
    },
    // 创建pageRequest websocket连接
    sendScoket () {
      let data = {
        type: 'taskInfo',
        module: 'taskInfo_TR',
        data: { peerIds: this.allPeerId.join() }
      }
      if (this.pageRequest) {
        this.pageRequest.stop('taskInfo_TR')
      }
      this.pageRequest && this.pageRequest.send({
        message: data,
        time: 1000,
        key: 'taskInfo_TR',
        success: res => {
          let liveArr = []
          if (res.result.length) {
            res.result.forEach(item => {
              if (item.status == '2') {
                const flag = this.sharingR.length && this.sharingR.indexOf(item.peerId) >= 0
                let rObj = {
                  device: item,
                  peerId: item.peerId,
                  shareInfo: flag ? this.videoObj[item.peerId].shareInfo : '',
                  shareSize: flag ? this.videoObj[item.peerId].shareSize : 0
                }
                liveArr.push(rObj)
              }
            })
          }
          this.videoList = liveArr
          this.total = this.videoList.length
          this.loading = false
          this.handleRShow()
          var taskInfo_TObj = this.pageRequest.sendMsgs.taskInfo_TR
          if (taskInfo_TObj) {
            taskInfo_TObj.message = {
              type: 'taskInfo',
              module: 'taskInfo_TR',
              data: { peerIds: this.allPeerId.join() }
            }
          }
        }
      })
    },
    upDataRInfo (obj) {
      let videoObj = {}
      this.sharingR = []
      if (obj.RInfo.length) {
        obj.RInfo.forEach(v => {
          videoObj[v.peerId] = v
          this.sharingR.push(v.peerId)
        })
      }
      this.videoObj = videoObj
      // this.videoList = obj.RInfo
      // this.total = obj.RInfo.length
      this.sharingSocial = obj.SocialInfo
    },
    searchR (val) {
      let regEn = /[`~!@#$%^&*()+<>?:"{},.\\/;'[\]]/im
      let regCn = /[·！#￥（——）：；“”‘、，|《。》？、【】[\\]]/im
      if (regEn.test(val) || regCn.test(val)) {
        this.$message.info(this.$t('lang.noChart'))
        return
      }
      this.params.condition = val
      mediaObj.searchKey = val
      this.refreshRList()
    },
    // 刷新
    refreshRList (tag) {
      if (tag == 'refresh') {
        this.params.condition = ''
        this.$refs.searchInput.search('')
        mediaObj.searchKey = ''
      } else {
        this.getCurrentUserR(tag)
      }
    },
    handleSizeChange (val) {
      this.pageSize = val
      this.currentPage = 1
    },
    handleCurrentChange (val) {
      this.currentPage = val
      this.refreshRList('changePage')
    },
    // 纯前端分页
    handleRShow (tag) {
      if (tag == 'refresh') this.currentPage = 1
      let maxPage = Math.ceil(this.total / this.pageSize) == 0 ? 1 : Math.ceil(this.total / this.pageSize)
      this.currentPage = maxPage < this.currentPage ? maxPage : this.currentPage
      let maxData = this.total
      let size = this.pageSize
      let intIndex = (this.currentPage - 1) * size
      let endIndex = maxData - intIndex >= size ? intIndex + size : intIndex + maxData - intIndex
      this.showRList = this.videoList.slice(intIndex, endIndex)
    }
  },
  components: {
    Source,
    SearchInput,
    Pagination
  },
  // 离开当前页面之前清空peerId值
  beforeRouteLeave (to, from, next) {
    mediaObj.searchKey = ''
    mediaObj.rId = ''
    next()
  }
}
</script>
<style lang="less" scoped>
.liveVideo {
  text-align: left;
  margin-top: 26px;
  .searchBox {
    .searchInput {
      display: inline-block;
      width: 300px;
      height: 32px;
      ::v-deep .el-input__inner {
        height: 32px;
        line-height: 32px;
        border: 1px solid #33ab4f;
      }
    }
    .refreshBtn {
      background: #333;
      color: #fff;
      height: 32px;
      width: 76px;
      padding: 0;
      margin-left: 20px;
    }
    .refreshBtn:hover {
      background: none;
    }
  }
  .mainBox {
    margin-top: 30px;
    min-height: 302px;
    .sourceBox {
      width: fit-content;
      // margin: 0 auto;
    }
  }
}
</style>

<template>
  <div
    class="mediaSourceList"
    :id="item.peerId"
    :sId="item.livePeerId"
    :share-data="shareInfo"
    r-type="WindowsR">
    <!--media框-->
    <span
      class="iconfont i-closeInput closeMediaIcon"
      @click="toggleShareBox()"
      v-show="shareFlag"></span>
    <MediaRBox
      v-if="shareFlag"
      :shareFlag="shareFlag"
      :rId="item.peerId"
      :sId="item.livePeerId"
      :id="item.peerId"
      :sType='item.type'
      :shareInfo="shareInfo" />
    <div
      class="source_img"
      :title="item.peerId">
      <img
        class="thumbnailImg"
        :src="freshImg"
        alt=""
        :onerror="logo">
      <div class="source_top">
        <span class="receiver_left">
          <p class="receiver_title">{{$t('lang.i18n_destination')}}</p>
          <p
            class="receiver_name ellipsis"
            :title="item.name">{{item.name}}</p>
        </span>
        <span class="receiver_right">
          <p class="receiver_title">{{$t('lang.source')}}</p>
          <p class="r_source_name ellipsis">{{item.livePeerName | filterSourceName(item.name,item.livePeerId,item.version)}}</p>
        </span>
      </div>
    </div>
    <div class="source_info">
      <div class="source_bottom">
        <span
          class="iconfont i-share receiver_left"
          @click="toggleShareBox()">{{$t('lang.i18n_moreShare')}}</span>
        <span
          class="sharingNum"
          v-if="shareSize != 0">{{shareSize}}</span>
        <span
          class="iconfont i-facebooklogo receiver_right"
          v-if="supportF"
          @click="goShare(item)">{{$t('lang.i18n_shareDirectory')}}</span>
      </div>
    </div>
  </div>
</template>
<script>
import MediaRBox from '@/components/mediaRbox'
export default {
  props: {
    item: Object,
    shareSize: {
      default: 0,
      type: Number
    },
    shareInfo: {
      default: '',
      type: String
    }
  },
  data () {
    return {
      shareFlag: false,
      share_loading: false,
      loadingText: this.$t('lang.i18n_sharing'),
      defaultImg: require('@/assets/img/logo_thumbnail.png'),
      logo: 'this.src="' + require('@/assets/img/logo_thumbnail.png') + '"', // 如果图片报错使用默认图片,并解决图片闪烁问题
      supportF: this.$store.state.common.baseUrl.mediaControl.demandPlatform.includes('F'),
      blobService: this.$store.state.common.baseUrl.blobUrl,
      deafultImg: require('@/assets/img/logo_thumbnail.png'),
      freshImg: '', // 要被刷新的图片的路径
      onlineImg: require('@/assets/img/logo_thumbnail.png'),
      offlineImg: require('@/assets/img/offlinePng.png'),
      imgUrlTime: Object
    }
  },
  watch: {
    $route (to, from) { // 切换及后退会触发路由变化
      this.activeName = to.name == 'SocialAccount' ? 'SocialAccount' : 'LiveVideo'
      if (this.activeName == 'LiveVideo') {
        mediaObj.mediaType = ''
        this.shareFlag = false
      }
    },
    shareSize: {
      handler (newVal, oldVal) {
        // console.log(newVal)
      },
      immediate: true,
      deep: true
    }
  },
  created () {
    if (this.item.status == 2) {
      this.freshImg = window.location.protocol + '//' + this.blobService + '/' + this.item.peerId + '?random=' + Math.random()
    } else {
      this.freshImg = this.deafultImg
    }

    setInterval(() => {
      if (this.item.status == 2) {
        // 不是在live的不能获取缩略图
        let blobUrl = ''
        const isCompressThumbnail = JSON.parse(localStorage.getItem('newCCInitConfig')).urlInfo && JSON.parse(localStorage.getItem('newCCInitConfig')).urlInfo.isCompressThumbnail == '0'
        if (this.item.livePeerId) {
          // 缩略图:开始使用source的Id 10秒后使用R的id
          if (this.item.livePeerType == 'X') {
            blobUrl = window.location.protocol + '//' + this.blobService + '/' + this.item.livePeerId + '?'
            blobUrl = isCompressThumbnail ? `${blobUrl}type=original&app=cc&${Math.random()}` : `${blobUrl}random=${Math.random()}&app=cc`
          } else {
            blobUrl = window.location.protocol + '//' + this.blobService + '/' + this.item.peerId + '?'
            blobUrl = isCompressThumbnail ? `${blobUrl}type=original&app=cc&${Math.random()}` : `${blobUrl}random=${Math.random()}&app=cc`
          }
        } else {
          blobUrl = this.deafultImg
        }
        this.freshImg = blobUrl
      }
    }, 5000)
  },
  methods: {
    goShare (item) {
      this.shareFlag = false
      mediaObj.mediaType = 'D'
      mediaObj.rId = item.peerId
      mediaObj.livePeerId = item.livePeerId
      mediaObj.changeType = ''
      SM.__proto__.JumpMediaJs()
    },
    // 控制分享box的显示隐藏
    toggleShareBox () {
      this.shareFlag = !this.shareFlag
      if (!this.shareFlag && typeof ShareBox === 'function' && typeof (ShareBox.close) === 'function') {
        ShareBox.close(this.item.peerId)
      }
    }
  },
  components: {
    MediaRBox
  },
  filters: {
    filterSourceName (livePeerName, name, livePeerId, version) {
      var sourceName
      if (livePeerId) {
        sourceName = livePeerName
      } else {
        sourceName = ''
      }
      if (version >= 5.6 && livePeerId != '' || name == 'CloudR') {
        var _className = (livePeerId && sourceName == '') ? 'r_localSource' : ''
        sourceName = _className != '' ? 'R local source' : sourceName
      }
      return sourceName
    }
  }
}
</script>
<style lang="less" scoped>
.mediaSourceList {
  width: 312px;
  height: 100%;
  background: #444;
  list-style: none;
  margin: 10px 10px 0 0;
  position: relative;
  float: left;
  cursor: auto;
  .source_img {
    .thumbnailImg {
      height: 174px;
      width: 100%;
      background: #000;
    }
    .source_top {
      height: 72px;
      padding: 10px;
      border-bottom: 1px solid #666;
      margin-bottom: 1px;
      span {
        width: 50%;
      }
      .receiver_left {
        float: left;
      }
      .receiver_title {
        color: #8c8a88;
        font-size: 14px;
      }
      .r_source_name {
        font-size: 16px;
        color: #ccc;
        width: 120px;
      }
      .receiver_name {
        font-size: 16px;
        width: 122px;
      }
    }
  }
  .source_info {
    color: #fff;
    span.receiver_left,
    span.receiver_right {
      display: inline-block;
      height: 100%;
      text-align: left;
      text-indent: 5px;
    }
    .receiver_right {
      float: right;
    }
    .sharingNum {
      padding: 2px 9px;
      height: 18px;
      min-width: 18px;
      width: fit-content;
      border-radius: 5px;
      line-height: 16px;
      cursor: pointer;
      text-align: center;
      color: #fff;
      background: #33aa50;
      margin-left: 5px;
      vertical-align: middle;
      display: inline-block;
    }
    .source_bottom {
      height: 45px;
      line-height: 45px;
      padding: 0 10px;
      font-size: 13px;
    }
  }
  .shareBox {
    display: none;
    // height: calc(100% - 45px);
    width: 100%;
    position: absolute;
    background: rgba(0, 0, 0, 0.8);
    top: 0;
    left: 0;
    .share_title_select {
      height: 36px;
      border-bottom: 1px solid #666;
      padding: 6px 6px 0 12px;
      .multiple_select {
        float: right;
        width: 166px;
        ::v-deep .dropdownDefault {
          border-radius: 2px;
          height: 25px;
          line-height: 25px;
        }
        ::v-deep .dropdown_menu {
          top: 28px;
        }
      }
    }
  }
  .share_display {
    display: block;
  }
}
</style>
